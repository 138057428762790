import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-tucson-arizona.png'
import image0 from "../../images/cities/scale-model-of-reid-park-zoo-in-tucson-arizona.png"
import image1 from "../../images/cities/scale-model-of-expedition-tanzania-in-tucson-arizona.png"
import image2 from "../../images/cities/scale-model-of-etherton-gallery-in-tucson-arizona.png"
import image3 from "../../images/cities/scale-model-of-tucson-museum-of-art-and-historic-block-in-tucson-arizona.png"
import image4 from "../../images/cities/scale-model-of-arizona-state-museum-in-tucson-arizona.png"
import image5 from "../../images/cities/scale-model-of-arizona-history-museum-in-tucson-arizona.png"
import image6 from "../../images/cities/scale-model-of-tucson-audubon-society-in-tucson-arizona.png"
import image7 from "../../images/cities/scale-model-of-michael-perry-park-in-tucson-arizona.png"
import image8 from "../../images/cities/scale-model-of-center-for-creative-photography-in-tucson-arizona.png"
import image9 from "../../images/cities/scale-model-of-sky-island-alliance-in-tucson-arizona.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Tucson'
            state='Arizona'
            image={image}
            lat='32.2226066'
            lon='-110.97471080000003'
            attractions={ [{"name": "Reid Park Zoo", "vicinity": "3400 E Zoo Ct, Tucson", "types": ["zoo", "point_of_interest", "establishment"], "lat": 32.2098547, "lng": -110.92074430000002}, {"name": "Expedition Tanzania", "vicinity": "3400 E Zoo Ct, Tucson", "types": ["zoo", "point_of_interest", "establishment"], "lat": 32.2080194, "lng": -110.91942519999998}, {"name": "Etherton Gallery", "vicinity": "135 S 6th Ave B, Tucson", "types": ["art_gallery", "point_of_interest", "establishment"], "lat": 32.220598, "lng": -110.96826599999997}, {"name": "Tucson Museum of Art and Historic Block", "vicinity": "140 N Main Ave, Tucson", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.2234281, "lng": -110.9754059}, {"name": "Arizona State Museum", "vicinity": "1013 E University Blvd, Tucson", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.23243, "lng": -110.95568320000001}, {"name": "Arizona History Museum", "vicinity": "949 E 2nd St, Tucson", "types": ["library", "museum", "point_of_interest", "establishment"], "lat": 32.2331542, "lng": -110.95742949999999}, {"name": "Tucson Audubon Society", "vicinity": "300 E University Blvd # 120, Tucson", "types": ["point_of_interest", "establishment"], "lat": 32.2312765, "lng": -110.96654390000003}, {"name": "Michael Perry Park", "vicinity": "8700 E Arbor St, Tucson", "types": ["park", "point_of_interest", "establishment"], "lat": 32.18956, "lng": -110.80520669999999}, {"name": "Center for Creative Photography", "vicinity": "1030 N Olive Rd, Tucson", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.2350258, "lng": -110.95474280000002}, {"name": "Sky Island Alliance", "vicinity": "406 S 4th Ave, Tucson", "types": ["point_of_interest", "establishment"], "lat": 32.2170747, "lng": -110.96589640000002}] }
            attractionImages={ {"Reid Park Zoo":image0,"Expedition Tanzania":image1,"Etherton Gallery":image2,"Tucson Museum of Art and Historic Block":image3,"Arizona State Museum":image4,"Arizona History Museum":image5,"Tucson Audubon Society":image6,"Michael Perry Park":image7,"Center for Creative Photography":image8,"Sky Island Alliance":image9,} }
       />);
  }
}